
import { Component, Vue } from 'vue-property-decorator';
import DateDocumentFilter from '@/modules/document-filters/components/date-document-filter.vue';
import FEATURES from '@/modules/common/constants/features.constant';
import CustomTooltipV2, { TOOLTIP_DIRECTION } from '@/modules/common/components/ui-kit/custom-tooltip-v2.vue';
import HotelSearchbar from '../_common/hotel-searchbar.vue';

@Component({
    components: {
        DateDocumentFilter,
        CustomTooltipV2,
        HotelSearchbar,
    },
})
export default class RatesClusterHeader extends Vue {
    RIGHT = TOOLTIP_DIRECTION.RIGHT;
    hint: HTMLElement | null = null;
    hintBoundings = {
        x: 0,
        y: 70,
    };

    get isUnderChainLevel() {
        // NOTE: Enable it when chain level will avalable
        // return this.$route.path.split('/')[1] === 'chain';
        return false;
    }

    get backRoute() {
        if (!this.isUnderChainLevel) return null;

        const hierarchy = this.$route.name!.split('.');
        hierarchy.pop();

        return {
            name: hierarchy.join('.'),
        };
    }

    get feature() {
        return this.$t(FEATURES.LITE_DI);
    }

    handleHintHover(e: MouseEvent) {
        this.hint = e.target as HTMLElement;
    }
}
