
import { inject } from '@/inversify';
import { Component, Vue } from 'vue-property-decorator';

import ClusterDiLiteService, { ClusterDiLiteServiceS } from '@/modules/cluster/cluster-di-lite.service';
import ClusterService, { ClusterServiceS } from '@/modules/cluster/cluster.service';
import ClusterHotelsRatesModel from '@/modules/cluster/models/cluster-rates.model';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import DiLiteAllChannelsService, { DiLiteAllChannelsServiceS } from '@/modules/di-lite/di-lite-all-channels.service';
import UserViewService, { UserViewServiceS } from '@/modules/user/user-view.service';

import HotelsList from '../../_common/hotels-list/index.vue';
import ListHeader from '../../_common/list-days-header.vue';
import DaysSet from './days-set.vue';

@Component({
    components: {
        HotelsList,
        ListHeader,
    },
})
export default class DiLiteHotelsList extends Vue {
    @inject(ClusterDiLiteServiceS) private clusterDiLiteService!: ClusterDiLiteService;
    @inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @inject(ClusterServiceS) private clusterService!: ClusterService;
    @inject(DiLiteAllChannelsServiceS) private diLiteAllChannelsService!: DiLiteAllChannelsService;
    @inject(UserViewServiceS) private userViewService!: UserViewService;

    get tableData() {
        const { hotels } = this.clusterDiLiteService;

        if (!hotels || !hotels.length) {
            return [];
        }

        return hotels.map(hotelData => ({
            table: [{
                value: hotelData.hotelName,
                onClick: () => this.toHotel(hotelData),
            }],
            component: {
                element: DaysSet,
                props: { hotelData },
            },
        }));
    }

    get headerData() {
        return [{
            value: 'hotelName',
            name: this.$tc('titles.hotelName'),
        }];
    }

    get component() {
        return {
            element: DaysSet,
            props: {},
        };
    }

    get pagesCount() {
        return this.clusterDiLiteService.hotelsCount;
    }

    get isLoading() {
        return this.clusterService.isLoading;
    }

    toHotel(hotelData: ClusterHotelsRatesModel) {
        const compset = hotelData.compsetMain?.id ? hotelData.compsets.find(c => c.id === hotelData.compsetMain!.id) : hotelData.compsets[0];
        this.userViewService.goToHotelPage(hotelData.hotelId, compset);
    }
}
